@import '../../style/varibale';

.main-container {
    display: flex;
    z-index: 9999;

    @media screen and (max-width:900px) {
        display: none;
    }

    main {
        padding: 10px;
    }
}

main {
    background-color: white !important;
}

/* Sidebar */
.sidebar {
    color: white;

    .top_section {
        display: flex;
        align-items: normal;
        justify-content: space-between;
        padding: 7px 18px;


        .logo {
            font-size: 18px;
            line-height: 0;
            /* padding: 30px 30px 0px 31px; */
            width: 129px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: $mainColor;
            -webkit-user-select: none;
            user-select: none;
            margin: auto;
        }

        .bars {
            cursor: pointer;
            color: $colorLight;
        }
    }
}

.routes {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 8px;


    .link {
        display: flex;
        color: $colorLight;
        gap: 16px;
        padding: 5px 18px;
        text-decoration: none;
        border-right: 4px solid transparent;
        transition: all 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
        cursor: pointer;
        align-items: center;



        .link_text {
            white-space: nowrap;
            text-decoration: none !important;
            text-decoration: underline;
            font-family: $fontBase;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 30px;
        }

        width: 0% !important;
    }

    .active {
        width: 100% !important; 
        width: 20px;
        position: relative;
        background-color: rgba($color: #fff, $alpha: .25);
        border-radius: 10px;
        transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    }

    .menu {
        display: flex;
        color: white;
        transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
        justify-content: space-between;
    }

    .menu_item {
        display: flex;
        gap: 10px;
    }

    .menu_container {
        display: flex;
        flex-direction: column;
        background-color: #18191A;
        transition: all 0.5s;

        .active {
            width: 20px;
        }

        margin-top: -12px;

        .link {
            display: flex;
            color: $colorLight;
            gap: 10px;
            text-decoration: none;
            transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);

            &:hover {
                transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
                color: $mainColor;
            }
        }

        .link_text {
            font-size: 13px !important;
        }

        .icon {
            svg {
                width: 5px !important;
                height: 10px !important;
            }
        }
    }

    .menu_container .link {
        padding-left: 20px;
    }
}


.routesAr {

    .link {
        &:hover {
            transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
            color: $secondColor;

        }

        width: 0% !important;
    }

    .active {
        width: 100% !important;
        color: $mainColor !important;
    }

    .menu_container { 
        .link {
            display: flex;
            color: $colorLight;
            gap: 10px;
            text-decoration: none;
            transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);

            &:hover {
                transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
                color: $secondColor;

            }
        }
    }

}
.side::-webkit-scrollbar {
    width: 5px; 
}

.side::-webkit-scrollbar-track {
    background: transparent;
}

.side::-webkit-scrollbar-thumb {
    background: #888;
}

.side::-webkit-scrollbar-corner {
    background-color: transparent;
}

.side::-webkit-scrollbar-thumb:hover {
    background: transparent; 
}
.side {
    height: 100%;
    width: inherit;
    position: fixed;
    top: 0;
    // left: 0;
    background-color: #000;
    z-index: 99;
    overflow: scroll;
    overflow-x: hidden;
    // display: none;
  }

.react-tooltip {
    z-index: 999999999999999 !important;
}

.main-container_nav {
    display: none;
}

@media screen and (max-width:900px) {

    .main-container_nav {
        display: block !important;
    }

    .adminLayout {
        width: 100% !important;
    }
}

.active .logoSvg path {
    width: 40px !important;
} 

.link_active {
    &:hover {
        background-color: rgba($color: #fff, $alpha: .15);
        border-radius: 10px;
    }

}

.link_active1 {
    .link {
        width: 100%;
    }
}

.link {
    .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 15px;
            height: 15px;
        }

        i {
            font-size: 12px;
        }
    }
}

.pi-angle-down {
    font-size: 12px !important;
}