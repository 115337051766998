@import '../../style/varibale';

.app__chat {
    margin: auto;
    width: 100%;
    background-color: #F5F8FA !important;

    @media screen and (max-width:400px) {
        width: 100% !important;
    }

    .app__chat__container {
        @include flex(flex, center, center, row);
        height: calc(100vh - 70px);
        padding: 10px;
        border-radius: 16px;

        @media screen and (max-width:770px) {
            min-height: calc(100vh - 70px) !important;
        }

    }

    .app__chat_list-Users {
        height: 100% !important;

        @media screen and (max-width:1000px) {
            display: none !important;
        }

        .app__chat_list-card {
            background-color: #FFFFFF;
            height: 100% !important;
            border-radius: 15px;
            padding: 10px  15px;
            // overflow-y: scroll;

            .app__chat_list-cards {
                padding-bottom: 29px;
            }

            .app__chat_list {
                overflow-y: scroll;
                max-height: 540px; 
                margin-top: 15px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                .nav-link {
                    padding: 8px 10px; 
                    background-color: #fff;
                }
                
                .nav-link:active,
                .nav-link:focus,
                .nav-link:hover {
                    background-color: rgba($color: #009ef7, $alpha: 0.1);  
                    border-radius: 5px;
                    color: #fff;
                    outline: none;
                }
                
                .user {
                    width: 100%;

                    @include flex(flex, space-between, center, row);
                    gap: 15px; 

                    .content {

                        .name {
                            font-weight: 700 !important;
                            font-size: 1.15rem !important;
                            text-decoration: none;
                        }

                        .email {
                            color: #B5B5C3;
                            font-weight: 600 !important;
                        }
                    }
                }
            }
        }
    }

    .app__chat_messages {
        height: 100% !important;

        @media screen and (max-width:1000px) {
            width: 100% !important;
        }

        @media screen and (max-width:400px) {
            width: 100% !important;
            margin: 0;
            padding: 0;
        }

        .app__chat_list-card {
            // padding: 20px !important;
            height: 100% !important;
            border-radius: 15px;
            padding: 5px;
            background-color: #FFFFFF;
            display: flex;
            flex-direction: column;
            align-content: space-between;
            gap: 0px;

            .app__Live_chat {
                // background: #d9d9d998;
                // border-radius: 16px;
                border-radius: 12px 12px 0 0;

                height: calc(100% - 10%) !important;
                padding: 10px 20px;
                padding-top: 15px !important;
                position: relative;

                .chat__header {
                    padding: 0 15px !important;
                    background-color: #D9D9D9 !important;
                    position: absolute;
                    top: 0;
                    display: flex;
                    margin: 0;
                    padding: 0;
                    align-items: center !important;
                    justify-content: space-between !important;
                    width: 100%;
                    // z-index: 9999;
                    left: 0;
                    right: 0;
                    border-radius: 12px 12px 0 0 !important;

                    .turn__off {
                        top: 0;
                        right: 0;
                        z-index: 9999;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        button {
                            font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                            font-size: 18px !important;
                        }

                        .power-button {
                            display: flex;
                            gap: 10px;
                            align-items: center;
                            justify-content: center;
                            // padding: 1rem;
                            padding: 10px 15px;
                            border: none;
                            border-radius: 16px;
                            transition: all 0.2s ease;
                            background-color: #f0f0f0;
                            transition: transform 0.2s ease;
                            transform: scale(0.8);
                        }

                        .power-button:focus {
                            outline: none;
                        }

                        .power-button.on {
                            background-color: #63d471;
                            color: white;
                        }

                        .power-button.off {
                            background-color: #e74c3c;
                            color: white;
                        }

                        .icon {
                            width: 1.1rem;
                            height: 1.1rem;
                        }

                        .power-button:hover {
                            cursor: pointer;
                        }

                        .power-button:focus {
                            outline: none;
                        }

                        .power-button.scaled {
                            transform: scale(0.7);
                        }

                    }
                }
            }

            .app__send {
                height: 10%;
                padding: 5px 10px;
                display: flex;
                align-items: center;
                background-color: #D9D9D9 !important;

                border-radius: 0 0 12px 12px;
                gap: 10px;

                input {
                    box-shadow: 2px 2px 2px #a29696;
                    border-radius: 17px !important;
                    background-color: #FFFFFF !important;
                }

                button {
                    background-color: $colorBlue-0;
                    border: $colorBlue-0;
                }
            }
        }
    }
}


.chat-body {
    height: calc(450px - (45px + 70px));
    border: 1px solid #D9D9D9;
    background: #fff;
    position: relative;
}

.chat-header p {
    display: block;
    padding: 0 1em 0 2em;
    color: #fff;
    font-weight: 700;
    line-height: 45px;
}

.chat-body .message-container {
    padding-top: 2.4rem !important;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.chat-body .message-container::-webkit-scrollbar {
    display: none;
}

.chat-body .message {
    height: auto;
    padding: 10px;
    display: flex;
}

.chat-body .message .message-content {
    width: auto;
    height: auto;
    // min-height: 40px;
    min-width: 120px;
    border-radius: 5px;
    display: flex;
    padding: 10px 10px;
    align-items: center;
    display: flex;
    align-items: center;
    overflow-wrap: break-word;
    word-break: break-word;

}

#you {
    justify-content: flex-start;
}

#you .message-content {
    align-items: center;
    justify-content: flex-start;
    background: #FFFFFF;
    padding-left: 10px;
    padding-top: 5px;
    border-radius: 31.5px 31.5px 31.5px 0px;
    width: 40% !important;
}

.message-content {
    p {
        margin: 0 !important;
    }

    img {
        width: 100% !important;
    }
}

#you .message-meta {
    display: flex;

    justify-content: flex-start;
    margin-left: 5px;
}

#other {
    margin-left: auto !important;
    max-width: 65% !important;
    display: flex;
    justify-content: flex-end;
}

#other .message-content {
    justify-content: flex-start;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 31.5px 31.5px 0px 31.5px;
    color: #000000;
    width: 50% !important;
    margin-left: auto;

}

#other .message-meta {
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
}

.message-meta #author {
    margin-left: 10px;
    font-weight: bold;
}

.chat-window .chat-body .message .message-meta {
    display: flex;
    font-size: 12px;
}

.empty_chat {
    font-size: larger;
    padding-top: 5px;
}

.bgChatBtn {
    background-color: $mainColor !important;
    border: none !important;
    transition: all 0.8s;

    &:hover {
        background-color: rgba($color: $mainColor , $alpha: 0.8) !important;
        box-shadow: none !important;
    }
}


.react-scroll-to-bottom--css-ddhjs-1n7m0yu {
    height: 100%;
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden !important;
}

#other .message {
    display: flex;
    justify-content: flex-end !important;
}

.app__chat_list-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #87888d;
    background-color: #f5f8fa;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.475rem;
}

.symbol-label {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

/***
 * 
 * .bg-light-warning {
    // background-color: #fff8dd !important;
    background-color: rgba($color: #ffc700, $alpha: 0.1);
}.text-warning {
    color: #ffc700 !important;
}
 */

.chatSupportStatus {
    color: #a1a5b7;
    font-size: 0.95rem !important
}

.message-content img {
    width: 100% !important;
}

.app__chat__Consult {
width: 100%;
    .app__chat {
        background-color: white !important;
    }

    .app__chat__Consult_details {
        background-color: #FAFAFA;
        box-shadow: 0px 3px 5px rgba(169, 169, 169, 0.16);
        border-radius: 5px;
        padding: 20px;
        width: 70%;
        margin-bottom: 30px !important;

        @media screen and (max-width:900px) {
            width: 100%;
        }

        .client,
        .doctor {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .client_details {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            gap: 4px;

            @media screen and (max-width:757px) {
                margin-top: 10px !important;
                justify-content: center;
            }

            label {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;

                /* Light Gray */

                color: #8C8C8C;
            }

            span {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;

                /* Light Gray */

                color: black;
            }
        }
    }

    .app__chat__container {
        padding: 10px 0 !important;
    }

    .app__chat .app__chat_messages .app__chat_list-card .app__Live_chat {
        border-radius: 12px !important;
        padding: 10px !important;
        position: relative;
        height: 100% !important;
    }

    .empty_chat {
        img {
            @media screen and (max-width:767px) {
                width: 50% !important;
            }
        }
    }
}

.admin__color{
    color: $secondColor;
}

.frinds__color{
    color: $mainColor;
}

// chat list not found

.app__chat_list{
    .no-result-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .no-result-image{
            width: 70%;
        }
    }
}