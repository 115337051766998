@import '../../../style/varibale';


.ant-pro-form-login-title {
    position: relative;
    inset-block-start: 2px;
    font-weight: 600;
    font-size: 30px;
    user-select: none;
}

.app__auth {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    overflow: auto;
    background-image: url(https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/V-_oS6r-i7wAAAAAAAAAAAAAFl94AQBr);
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;


    .app__login-left {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }
}
.login-form{
    
    width: 350px;
}
 
 
 

.spinner-border {
    color: white !important;
    width: 25px !important;
    height: 25px !important;
}

 
.password_open {
    position: relative;

    .app__login-btn-icons {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        cursor: pointer;
        width: 20px !important;

    }
}


 