@import '../style/varibale';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.p-password input {
  width: 100%;
  font-size: 11px;
}

.password-input {
  position: relative;
}

.Password_open {

  position: relative;
  .app__login-btn-icons {
      position: absolute;
      top: 64%;
      transform: translateY(-50%);
      right: 20px;
      cursor: pointer;
      width: 20px !important;

  }

  .p-password {
      width: 100% !important;
  }

}

[dir="rtl"] .Password_open .app__login-btn-icons {
  position: absolute;
  top: 64%;
  transform: translateY(-50%);
  right: 92%;
  cursor: pointer;
  width: 20px !important;
}

body {
  background: $colorLight;
  font-family: 'Cairo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.modal {
  z-index: 999999997 !important;
}

::-webkit-scrollbar {
  width: 7px; /* عرض الشريط العمودي */
  height: 5px; /* عرض الشريط الجانبي */
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $secondColor;
  border-radius: 10px;
  // border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba($color: $secondColor, $alpha: 0.8);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

 
.custom-datatable ::-webkit-scrollbar {
  width: 4px; /* Vertical scrollbar width */
  height: 5px; /* Horizontal scrollbar height */
}

.custom-datatable ::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-datatable ::-webkit-scrollbar-thumb {
  background-color: rgba($color: #dddada, $alpha: 0.8);
}

.custom-datatable ::-webkit-scrollbar-thumb:hover {
  background-color: rgba($color: #dddada, $alpha: 0.8);
}

.custom-datatable ::-webkit-scrollbar-corner {
  background-color: transparent;
}

td {
  vertical-align: middle !important;
}

.ClientPhone {
  direction: ltr !important;
}



.p-column-title {
  width: 100% !important;
  text-align: center !important;
}

.p-galleria .p-galleria-item-nav {
  background-color: rgba($color: #000000, $alpha: 0.3) !important;

  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.2) !important;
  }
}

.bg_delete {
  // background-color: #DC2626 !important;
  cursor: pointer;
  bottom: 8px !important;
  right: 8px !important;
}


[dir="rtl"] .btn_gapAr {
  .p-button {
    gap: 9px;
  }
}

// [dir="rtl"] .p-button {
//   display: flex;
//   gap: 10px;
// }


[dir="rtl"] .p-dropdown-item {
  display: flex !important;
  justify-content: start !important;
}


.p-dropdown-item {
  display: flex !important;
  justify-content: end !important;
}

 


.p-dropdown-item {
  display: flex !important;
  justify-content: end !important;
}

.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.1rem rgba($color: $mainColor, $alpha: 0.2) !important;
  border-color: $mainColor;
}

.p-inputtext {  
  // border-color: $mainColor !important;
}

.p-dropdown {
  background: #ffffff;
  // border: 1px solid $mainColor !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
  border-radius: 6px;
}

.p-inputtext:not(.p-disabled):hover ,
  .p-dropdown:not(.p-disabled):hover {
  border-color: $mainColor !important;
}

.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem #d48c1f6e !important;
  border-color: $mainColor !important;
}